import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { of } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() submitted?: boolean;
  @Input() isUsingAdvance: boolean;
  @Input() isNoPeriod: boolean;
  @Input() noExpired: boolean;
  @Input() date: any;
  @Input() validateInput: boolean;
  @Input() validateSubmitted: boolean;
  @Input() isDisabled: boolean;
  @Output() dateChange = new EventEmitter();
  @Output() daysEmit = new EventEmitter<NgbDateStruct | any>();
  @Output() dateEmit = new EventEmitter<NgbDateStruct | any>();
  @Output() dateClear = new EventEmitter<NgbDateStruct | any>();
  @Input() errorMsg = '';
  @Input() today = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  calculateDays(): void {
    const date = this.date.split('-');
    const e = moment({
      date: parseInt(date[2], 10),
      month: parseInt(date[1], 10) - 1,
      year: parseInt(date[0], 10),
    });
    const s = moment({
      year: this.today.year,
      month: this.today.month - 1,
      date: this.today.day,
    });
    const day = e.diff(s, 'days');
    this.daysEmit.emit(day);
    this.dateEmit.emit(this.date);
  }

  clearDate() {
    this.date = '';
    this.dateClear.emit(this.date);
  }
}
