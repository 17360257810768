import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApprovalDropdownModule } from '../modules/memos/components/memo-creation/approval-dropdown/approval-dropdown.module';
import { CommaPipe } from './pipes/comma.pipe';
import { PermissionDirective } from './directives/permission.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PreviewPdfComponent } from './components/preview-pdf/preview-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { FormValidationDirective } from './directives/form-validation.directive';
import { FormSubmitValidationDirective } from './directives/form-submit-validation.directive';
import { CanvasWhiteboardModule } from '@preeco-privacy/ng2-canvas-whiteboard';
import { BackButtonDirective } from './directives/back-button.directive';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DepartmentDropdownComponent } from './components/department-dropdown/department-dropdown.component';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { LaddaModule } from 'angular2-ladda';
import { LoaNameThPipe } from './pipes/loa-name-th.pipe';
import { HumanDatetimePipe } from './pipes/human-datetime.pipe';
import { ColorSketchModule } from 'ngx-color/sketch';
import { HammerModule } from '@angular/platform-browser';
import { ImageUploadPreviewComponent } from './components/image-upload-preview/image-upload-preview.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { UploadFileExcelComponent } from './components/upload-file-excel/upload-file-excel.component';
import { CommentComponent } from './components/comment/comment.component';
import { AutosizeModule } from 'ngx-autosize';
import { MentionModule } from 'angular-mentions';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ApprovalConfirmModalComponent } from './components/approval-confirm-modal/approval-confirm-modal.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SelectSignMethodModalComponent } from './components/select-sign-method-modal/select-sign-method-modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DeleteConfirmationModalComponent } from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ImportProgressComponent } from './components/import-progress/import-progress.component';
import { TreeDropdownModule } from './components/tree-dropdown/tree-dropdown.module';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SecurePipe } from './pipes/secure.pipe';
import { MentionHighlightDirective } from './directives/mention-highlight.directive';
import { ContentEditableDirective } from './directives/content-editable.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BuddhistDatePipe } from './pipes/date.pipe';
import { ImageProcessingService } from './service/image-processing.service';
import { LinkifyPipe } from '../modules/memos/components/upload-memos/pdf-signature-customizer/pipe/linkfy.pipe';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ExportExcelModalComponent } from './components/export-excel-modal/export-excel-modal.component';
import { PhonePipe } from './pipes/phone.pipe';
import { PermissionNotFoundComponent } from './components/permission-not-found/permission-not-found.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxResizeObserverModule } from 'ngx-resize-observer';
import { ModalConfirmationModule } from './components/modal-confirmation/modal-confirmation.module';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { ModalPdfPreviewStd4Component } from './components/modal-pdf-preview-std4/modal-pdf-preview-std4.component';
import { ModalConfirmationStd4SkinComponent } from './components/modal-confirmation-std4-skin/modal-confirmation-std4-skin.component';
import { InputTypeNumberComponent } from '@shared/components/input-type-number/input-type-number.component';
import { MemoNoPermissionComponent } from '@shared/components/memo-no-permission/memo-no-permission.component';
import { ProfileDisplayComponent } from '@shared/components/profile-display/profile-display.component';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { BubbleDropdownComponent } from './components/bubble-dropdown/bubble-dropdown.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { TextSignatureModule } from '../modules/profile/component/text-signature/text-signature.module';
import { SignatureModule } from '@shared/components/signature/signature.module';

export function createTranslateLoader(
  http: HttpClient,
): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Local Components

@NgModule({
  imports: [
    ApprovalDropdownModule,
    BubbleDropdownComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    ModalConfirmationModule,
    NgSelectModule,
    NgbModule,
    PdfViewerComponent,
    ProfileDisplayComponent,
    SecurePipe,
    TranslateModule,
    NgxSpinnerModule,
    CarouselModule,
    PdfViewerModule,
    HammerModule,
    CanvasWhiteboardModule,
    ImageCropperModule,
    TreeModule,
    LaddaModule,
    ColorSketchModule,
    AutosizeModule,
    MentionModule,
    NgClickOutsideDirective,
    NgOtpInputModule,
    TreeDropdownModule,
    NgxFileDropModule,
    AngularSvgIconModule.forRoot(),
    NgxResizeObserverModule,
    ModalConfirmationStd4SkinComponent,
    TextSignatureModule,
    SignatureModule,
    InputTypeNumberComponent,
    PaginationComponent,
  ],
  exports: [
    ApprovalDropdownModule,
    BubbleDropdownComponent,
    LoadingSpinnerComponent,
    ModalConfirmationModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    NgxSpinnerModule,
    CommaPipe,
    PermissionDirective,
    CarouselModule,
    PreviewPdfComponent,
    PdfViewerModule,
    SvgIconComponent,
    FormValidationDirective,
    FormSubmitValidationDirective,
    BackButtonDirective,
    ScrollTrackerDirective,
    DateRangePickerComponent,
    ImageCropperModule,
    DepartmentDropdownComponent,
    DeleteConfirmationModalComponent,
    LaddaModule,
    LoaNameThPipe,
    HumanDatetimePipe,
    ColorSketchModule,
    ImageUploadPreviewComponent,
    PdfViewerComponent,
    UploadFileExcelComponent,
    CommentComponent,
    ApprovalConfirmModalComponent,
    SelectSignMethodModalComponent,
    ImportProgressComponent,
    FeatureFlagDirective,
    NgClickOutsideDirective,
    OtpModalComponent,
    TreeDropdownModule,
    SecurePipe,
    MentionHighlightDirective,
    ContentEditableDirective,
    SafeHtmlPipe,
    LinkifyPipe,
    ConfirmModalComponent,
    ExportExcelModalComponent,
    PhonePipe,
    PermissionNotFoundComponent,
    LoadingProgressBarComponent,
    ModalPdfPreviewStd4Component,
    ProfileDisplayComponent,
    SnackBarComponent,
    MemoNoPermissionComponent,
    DatePickerComponent,
    PasswordInputComponent,
  ],
  declarations: [
    LoadingSpinnerComponent,
    CommaPipe,
    PermissionDirective,
    PreviewPdfComponent,
    SvgIconComponent,
    FormValidationDirective,
    FormSubmitValidationDirective,
    BackButtonDirective,
    ScrollTrackerDirective,
    DateRangePickerComponent,
    DepartmentDropdownComponent,
    DeleteConfirmationModalComponent,
    LoaNameThPipe,
    HumanDatetimePipe,
    ImageUploadPreviewComponent,
    UploadFileExcelComponent,
    CommentComponent,
    ApprovalConfirmModalComponent,
    SelectSignMethodModalComponent,
    ImportProgressComponent,
    FeatureFlagDirective,
    OtpModalComponent,
    MentionHighlightDirective,
    ContentEditableDirective,
    SafeHtmlPipe,
    BuddhistDatePipe,
    LinkifyPipe,
    ConfirmModalComponent,
    ExportExcelModalComponent,
    PhonePipe,
    PermissionNotFoundComponent,
    LoadingProgressBarComponent,
    ModalPdfPreviewStd4Component,
    SnackBarComponent,
    MemoNoPermissionComponent,
    DatePickerComponent,
    PasswordInputComponent,
  ],
  providers: [ImageProcessingService],
})
export class SharedModule {}
