<div class="background-blur">
  <div #pdfView
      class="pdf-preview"
      (onResize)="onPdfViewResized($event)"
      @inOutPaneAnimation>
    <div *ngIf="!pdfFile"
        class="no-pdf">
      <div class="text-center"
          style="max-width: 60%">
        <svg class="icon-empty-page"
            height="100"
            viewBox="0 0 380 150"
            width="">
          <use href="assets/images/pictograms/paint.svg#paint"
              height="124"
              width=""
              y="8"
              fill="#EAEAED">
          </use>
          <use href="assets/images/pictograms/doc.svg#doc"
              height="140"
              width=""
              x="127">
          </use>
        </svg>
        <ng-container *ngIf="memo.password_protected; else noDataTemplate">
          <div>
            <h5 class="fw-bold mb-1">
              {{ 'MEMOS.This document cannot be viewed' | translate }}
            </h5>
            <p class="text-gray-neutrals mb-0">
              {{ 'MEMOS.You must enter your password first. Click on View Document Details' | translate }}
            </p>
          </div>
        </ng-container>
        <ng-template #noDataTemplate>
          <div>
            <h5 class="fw-bold mb-1">
              <span>{{ 'MEMOS.No data' | translate }}</span>
            </h5>
            <p class="text-gray-neutrals mb-0">
              {{ 'MEMOS.This memo has not been filled with content or uploaded data yet' | translate }}
            </p>
          </div>
        </ng-template>
      </div>
      <ng-container *ngTemplateOutlet="footer; context: { $implicit: 0 }"></ng-container>
      <ng-container *ngTemplateOutlet="pdfIcon; context: { $implicit: '-45' }"></ng-container>
    </div>
    <pdf-viewer *ngIf="pdfFile && !isRefreshPDF"
        style="width:100%;height:100%"
        [src]="{
          url: pdfFile,
          httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders : undefined)
        }"
        [(page)]="currentDocumentPage"
        [render-text]="false"
        [original-size]="false"
        [show-all]="false"
        [zoom]="1"
        [zoom-scale]="'page-fit'"
        [c-maps-url]="'/assets/cmaps/'"
        (after-load-complete)="onLoadPdfComplete($event)"
        (page-rendered)="pageRendered($event)">
    </pdf-viewer>
    <ng-container *ngIf="pdfFile && !isRefreshPDF">
      <ng-container *ngTemplateOutlet="footer; context: { $implicit: right+40 }"></ng-container>
      <ng-container *ngTemplateOutlet="pdfIcon; context: { $implicit: right }"></ng-container>
    </ng-container>
    <ng-template #pdfIcon
      let-right>
      <div *ngIf="!pdfFile || !this.isDocumentLoadingPage"
          class="icon-pdf d-flex flex-row flex-md-column"
          [ngStyle]="{
          'right.px': right,
        }">
        <div (click)="close()">
          <a class="icon-circle btn-close-pdf"
              href="javascript:void(0)">
            <svg height="15"
                stroke="#F53636"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/marks/x.svg#x">
              </use>
            </svg>
          </a>
        </div>
        <div *ngIf="pdfFile"
            class="order-first order-md-last pointer">
          <a class="icon-circle"
              href="javascript:void(0)"
              (click)="downloadDocument()">
            <svg height="15"
                stroke="#55596D"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/arrows/arrow-down-to-line.svg#arrow-down-to-line">
              </use>
            </svg>
          </a>
        </div>
        <div *ngIf="pdfFile"
            class="order-first order-md-last pointer">
          <a class="icon-circle"
              href="javascript:void(0)"
              (click)="printDocument()">
            <svg height="15"
                stroke="#55596D"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/devices/printer.svg#printer">
              </use>
            </svg>
          </a>
        </div>
        <div *ngIf="showingAttachment"
            class="order-first order-md-last pointer">
          <a class="icon-circle rounded-circle"
              href="javascript:void(0)"
              (click)="goToAttachment()">
            <svg height="15"
                stroke="#55596D"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/objects/paperclip.svg#paperclip">
              </use>
            </svg>
          </a>
          <div *ngIf="memo?.attachment_count !== 0"
              class="notification-attachment badge rounded-pill text-white"
              [style.background-color]="'#EE2E22'">
            <span>{{ memo.attachment_count }}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footer
      let-right>
      <div *ngIf="!pdfFile || !this.isDocumentLoadingPage"
          class="footer-pdf row m-0 align-items-center p-3"
          [ngStyle]="{
          'width': this.pdfFile ? pageWidth[currentDocumentPage] + 'px': '100%',
          'top': this.pdfFile ? 'calc(' + pageHeight[currentDocumentPage] + 'px - 4rem)': '',
          'bottom': this.pdfFile ? '': '0',
          'right': right + 'px',
          'max-width': this.pdfFile ? '': '550px'
        }">
        <div *ngIf="pdfFile"
            class="col-auto p-0 page-count d-flex align-items-center">
          <span>
            <app-input-type-number [min]="1"
                [max]="totalDocumentPage"
                [(model)]="currentDocumentPage"></app-input-type-number>
          </span>
          <span class="ms-1">
            {{'UPLOAD.OF' | translate}} {{totalDocumentPage}} {{'MEMOS.pages' | translate}}
          </span>
        </div>
        <div *ngIf="showingViewDetail"
            class="col text-end p-0">
          <button class="btn btn-std-prim mx-1 btn-std-color-1-primary"
              (click)="goToDetail()"
              [ngStyle]="{'font-size': this.pdfFile && pageWidth[currentDocumentPage] < 385 ? '0.7rem': '',}">
            <svg-icon src="assets/images/icons/accessibility/info-circle.svg#info-circle"
                [svgStyle]="{'stroke': '#FFF','width.px': 15, 'height.px': 15, 'margin-bottom.px': 2}"></svg-icon>
            {{'MEMOS.View memo detail' | translate}}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>