<div *ngIf="navbarActive"
    class="d-flex"
    id="wrapper"
    [style.height]="'-webkit-fill-available'">
  <div class="d-flex flex-column"
      id="page-content-wrapper"
      [ngClass]="isUserActive()">
    <app-navbar [profile]="profile">
      <div class="position-absolute position-logo justify-content-center justify-content-md-start px-md-l7 px-lg-l6">
        <div class="btn-sidebar-toggle d-flex align-items-center px-base-l0 px-base-md-l2 ms-md-l7 text-std-color-5">
          <svg-icon class="icon-toggle-sidebar d-flex pointer align-self-center text-gray-neutrals"
              [src]="deviceType === 'mobile'
                ? !openSidebar
                  ? 'assets/images/icons/graphics/bars.svg'
                  : 'assets/svg/icon-menu/menu-show-sidebar.svg'
                : isFullSidebar
                  ? 'assets/svg/icon-menu/menu-show-sidebar.svg'
                  : 'assets/svg/icon-menu/menu-hide-sidebar.svg'"
              [svgStyle]="{
                'height': 'inherit',
                'stroke': 'currentColor',
                'stroke-width.px': 1.5,
                'width': 'inherit'
              }"
              (click)="toggleSidebar()">
          </svg-icon>
        </div>
        <img alt=""
            [src]="themeList?.logo_rec
                ? (themeList?.logo_rec | secure | async)
                : 'assets/images/codium_logo.png' "
            [style.max-height.px]="50">
      </div>
    </app-navbar>
    <div class="d-flex position-relative flex-fill">
      <app-modal-pdf-preview-std4 *ngIf="isPreview">
      </app-modal-pdf-preview-std4>
      <!-- Sidebar -->
      <div #sidebar
          class="sidebar-wrapper"
          [class.sidebar-hidden]="!openSidebar">
        <div class="width-sidebar sidebar text-normal bg-std-color-1 d-flex flex-column"
            [class.sidebar-shrink]="!isFullSidebar"
            (window:resize)="onResize($event)">
          <div *ngIf="openSidebar"
              class="d-flex py-3 px-4 align-items-center">
            <app-profile-display [person]="profile"
                [size]="40"
                [pointer]="true"
                (clickProfile)="navigateToProfile()"></app-profile-display>
            <div class="d-flex px-3 flex-column">
              <div class="fw-semibold fs-base-l3">
                <span class="text-nowrap"
                    id="sidebar-user">
                  {{ profile.shorted_name }}
                </span>
              </div>
              <div class="pt-1 fs-base-l7">
                <label class="pointer m-0 fw-normal"
                    (click)="changeLanguage('en')"
                    [class.fw-bold]="selectedLanguage === 'en'"
                    [class.text-bold]="selectedLanguage === 'en'">EN</label>
                <label class="fw-bold text-bold m-0"> \ </label>
                <label class="pointer m-0 fw-normal"
                    (click)="changeLanguage('th')"
                    [class.fw-bold]="selectedLanguage === 'th'"
                    [class.text-bold]="selectedLanguage === 'th'">TH</label>
              </div>
            </div>
          </div>
          <!-- /#sidebar-wrapper -->
          <div class="list-group list-group-flush pt-0 pt-md-4 px-l7 px-lg-l6"
              [ngClass]="isFullSidebar ? 'text-start':'text-hidden'"
              (clickOutside)="closeSidebar($event)">
            <div class="menu-main overflow-auto"
                [style.--padding]="(isFullSidebar ? '1.5':'1.5') + 'rem'">
              <!-- News Feed -->
              <div #rla="routerLinkActive"
                  *appCheckPermission="['Admin', 'Master Admin', 'General']"
                  class="mt-2"
                  routerLinkActive="active"
                  [routerLink]="['/', 'news-feed']"
                  ngbTooltip="{{'SIDEBAR.NEWS-FEED' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/images/icons/files/file-earmark-richtext.svg#file-earmark-richtext">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2 text-truncate-clip">
                    {{ 'SIDEBAR.NEWS-FEED' | translate }}
                  </span>
                </a>
              </div>
              <!-- Announcement -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'announcement']"
                #rla="routerLinkActive"
                  *appCheckPermission="['Admin', 'Master Admin', 'General']"
                  ngbTooltip="{{'SIDEBAR.Announcement' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right"
                  class="mt-2">
                <a class="list-group-item list-group-item-action notification">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/svg/icon-menu/blog.svg">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2 text-truncate-clip">
                    {{ 'SIDEBAR.Announcement' | translate }}
                  </span>
                  <span *ngIf="badge()['waitingAnnouncement']"
                      class="d-inline-block"
                      [ngClass]="isFullSidebar ? 'badge-number': 'badge-number-hide'">
                    {{ badge()['waitingAnnouncement'] }}
                  </span>
                </a>
              </div>
              <!-- Approval -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'pending-approval']"
                #rla="routerLinkActive"
                  *appCheckPermission="['General', 'Admin']"
                  ngbTooltip="{{'SIDEBAR.PENDING' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action notification">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/svg/icon-menu/document-tasks.svg"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2 text-truncate-clip">
                    {{ 'SIDEBAR.PENDING' | translate }}
                  </span>
                  <span *ngIf="notificationService.memoNotificationCount > 0"
                      class="d-inline-block"
                      [ngClass]="isFullSidebar ? 'badge-number': 'badge-number-hide'">
                    {{
                      notificationService.memoNotificationCount > 99
                        ? '99+'
                        : notificationService.memoNotificationCount
                    }}
                  </span>
                </a>
              </div>
              <!-- Memo -->
              <div routerLinkActive
                  [routerLink]="['/', 'memos']"
                #rla="routerLinkActive"
                  *appCheckPermission="['General', 'Admin']"
                  [ngClass]="{ 'active' : rla.isActive && !isTrashModule }"
                  ngbTooltip="{{'MEMOS.MEMO-LIST' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <!-- Manage Users -->
                  <div [class.select-icon]="rla.isActive && !isTrashModule"></div>
                  <svg-icon src="assets/images/icons/files/file-earmark-lines-copy.svg"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2 text-truncate-clip">
                    {{ 'MEMOS.MEMO-LIST' | translate }}
                  </span>
                </a>
              </div>
              <!-- Folder -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'folders']"
                #rla="routerLinkActive"
                  *appCheckPermission="['General', 'Admin']"
                  ngbTooltip="{{'SIDEBAR.FOLDER' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action notification">
                  <!-- Manage Users -->
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/svg/icon-menu/folder.svg"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2 text-truncate-clip">
                    {{ 'SIDEBAR.FOLDER' | translate }}
                  </span>
                </a>
              </div>
              <!-- Management -->
              <div class="custom-border custom-border-top"
                  *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                  [ngStyle]="{'background': managementSubMenu? '': ''}">
                <div routerLinkActive="active"
                  #rla="routerLinkActive"
                    class="mt-2 hasSubMenu"
                    *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                    placement="right"
                    ngbTooltip="{{'SIDEBAR.MANAGEMENT' | translate}}"
                    [disableTooltip]="isFullSidebar"
                    (click)="managementSubMenu=!managementSubMenu">
                  <a class="list-group-item list-group-item-action position-relative ">
                    <div [class.select-icon]="rla.isActive"></div>
                    <svg-icon src="assets/images/icons/objects/gear.svg#gear"
                        class="mt-title-svg"
                        [stretch]="true">
                    </svg-icon>
                    <span *ngIf="isFullSidebar"
                        class="w-100 ps-2">
                      {{ 'SIDEBAR.MANAGEMENT' | translate }}
                    </span>
                    <div class="text-end ps-2 position-absolute"
                        [ngClass]="isFullSidebar? 'position-arrow' : 'position-arrow-hidden'">
                      <i class="fal"
                          [ngClass]=" managementSubMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
                    </div>
                  </a>
                </div>
                <ng-container *ngIf="managementSubMenu">
                  <!-- Level of Hierarchy -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'levels-hierarchy']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                      ngbTooltip="{{'SIDEBAR.LEVEL-OF-HIERARCHY' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/objects/my-mapping.svg#my-mapping"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0' "
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.LEVEL-OF-HIERARCHY' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- Department Level -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'department', 'level']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                      ngbTooltip="{{'SIDEBAR.DEPARTMENT-LEVEL' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/people/person-star.svg#person-star"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0' "
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.DEPARTMENT-LEVEL' | translate }}
                      </span>
                    </a>
                  </div>

                  <!-- Department -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'department', 'add']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                      ngbTooltip="{{'SIDEBAR.DEPARTMENT' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/people/people.svg#people"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.DEPARTMENT' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- Manage Users -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'users']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                      ngbTooltip="{{'SIDEBAR.MANAGE-USERS' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <!-- Manage Users -->
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/people/person.svg#person"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.MANAGE-USERS' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- LOA -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'loa']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.MANAGE-APPROVAL' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/people/user-cert-check.svg#user-cert-check"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.MANAGE-APPROVAL' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- CC Group -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'cc-group']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.MANAGE-CC-GROUP' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/accessibility/cc2.svg#cc2"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.MANAGE-CC-GROUP' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- All Document (Trash) -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'memos', 'trash']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                      ngbTooltip="{{'SIDEBAR.ALL-DOCUMENTS' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/communications/inbox-doc.svg#inbox-doc"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.ALL-DOCUMENTS' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- upload memo type -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'manage-types-upload-memo', 'type']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.UPLOAD-MEMO-TYPE' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/files/move-to-folder.svg#move-to-folder"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.UPLOAD-MEMO-TYPE' | translate }}
                      </span>
                    </a>
                  </div>
                  <!-- Others -->
                  <div routerLinkActive="active"
                      [routerLink]="['/', 'other-settings', 'memo-settings']"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin']"
                      ngbTooltip="{{'SIDEBAR.Others' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      placement="right">
                    <a class="list-group-item list-group-item-action">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/objects/puzzle.svg#puzzle"
                          [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="ps-2">
                        {{ 'SIDEBAR.Others' | translate }}
                      </span>
                    </a>
                  </div>
                </ng-container>
              </div>
              <!-- Certificate -->
              <ng-container *appFeatureFlag="'ddoc'">
                <div class="custom-border"
                    *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                    [ngStyle]="{'background': manageCertificate? '': ''}">
                  <!-- manage certificate -->
                  <div routerLinkActive="active"
                      class="mt-2 hasSubMenu"
                    #rla="routerLinkActive"
                      *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                      placement="right"
                      ngbTooltip="{{'CSR.CERTIFICATE' | translate}}"
                      [disableTooltip]="isFullSidebar"
                      (click)="manageCertificate = !manageCertificate">
                    <a class="list-group-item list-group-item-action position-relative">
                      <div [class.select-icon]="rla.isActive"></div>
                      <svg-icon src="assets/images/icons/files/file-earmark-star.svg#file-earmark-star"
                          class="mt-title-svg"
                          [stretch]="true">
                      </svg-icon>
                      <span *ngIf="isFullSidebar"
                          class="w-100 ps-2">
                        {{ 'CSR.CERTIFICATE' | translate }}
                      </span>
                      <div class="text-end ps-2 position-absolute"
                          [ngClass]="isFullSidebar ? 'position-arrow' : 'position-arrow-hidden'">
                        <i class="fal"
                            [ngClass]=" manageCertificate ? 'fa-angle-up' : 'fa-angle-down'">
                        </i>
                      </div>
                    </a>
                  </div>
                  <!-- manage csr -->
                  <ng-container *ngIf="manageCertificate">
                    <ng-container *appFeatureFlag="'ddoc_enable_feature_28'">
                      <div routerLinkActive="active"
                          [routerLink]="['/', 'manage-csr']"
                        #rla="routerLinkActive"
                          *appCheckPermission="['Admin', 'Master Admin']"
                          ngbTooltip="{{'CSR.CSR-LIST' | translate}}"
                          [disableTooltip]="isFullSidebar"
                          placement="right">
                        <a class="list-group-item list-group-item-action">
                          <div [class.select-icon]="rla.isActive"></div>
                          <svg-icon src="assets/images/icons/files/doc-pen.svg#doc-pen"
                              [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                              [stretch]="true">
                          </svg-icon>
                          <span *ngIf="isFullSidebar"
                              class="ps-2">
                            {{ 'CSR.CSR-LIST' | translate }}
                          </span>
                        </a>
                      </div>
                    </ng-container>
                  </ng-container>
                  <!-- Manage Certificate -->
                  <ng-container *ngIf="manageCertificate">
                    <div routerLinkActive="active"
                        [routerLink]="['/', 'certificate']"
                      #rla="routerLinkActive"
                        *appCheckPermission="['Admin', 'Master Admin', 'Auditor']"
                        ngbTooltip="{{'SIDEBAR.CERTIFICATE' | translate}}"
                        [disableTooltip]="isFullSidebar"
                        placement="right">
                      <a class="list-group-item list-group-item-action">
                        <!-- Manage Certificate -->
                        <div [class.select-icon]="rla.isActive"></div>
                        <svg-icon src="assets/images/icons/files/file-doc-star.svg#file-doc-star"
                            [ngClass]="isFullSidebar ? 'ms-4' : 'ms-0'"
                            [stretch]="true">
                        </svg-icon>
                        <span *ngIf="isFullSidebar"
                            class="ps-2">
                          {{ 'SIDEBAR.CERTIFICATE' | translate }}
                        </span>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <!-- Dashboard -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'kpi-dashboard']"
                #rla="routerLinkActive"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.DASHBOARD' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/images/icons/business/chart-simple.svg#chart-simple"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2">
                    {{ 'SIDEBAR.DASHBOARD' | translate }}
                  </span>
                </a>
              </div>
              <!-- Usage Dashboard -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'usage-dashboard', 'usage']"
                #rla="routerLinkActive"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  [ngbTooltip]="'SIDEBAR.Package-Usage' | translate"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/images/icons/objects/gauge.svg#gauge"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2">
                    {{ 'SIDEBAR.Package-Usage' | translate }}
                  </span>
                </a>
              </div>
              <!-- Operation Log -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'operation-log']"
                #rla="routerLinkActive"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.OPERATION-LOG' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/images/icons/devices/database-magnifying-glass.svg#database-magnifying-glass"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2">
                    {{ 'SIDEBAR.OPERATION-LOG' | translate }}
                  </span>
                </a>
              </div>
              <!-- Config -->
              <div routerLinkActive="active"
                  [routerLink]="['/', 'configuration', 'logo']"
                #rla="routerLinkActive"
                  *appCheckPermission="['Admin', 'Master Admin']"
                  ngbTooltip="{{'SIDEBAR.CONFIGURATION' | translate}}"
                  [disableTooltip]="isFullSidebar"
                  placement="right">
                <a class="list-group-item list-group-item-action">
                  <div [class.select-icon]="rla.isActive"></div>
                  <svg-icon src="assets/images/icons/graphics/venn-diagram.svg#venn-diagram"
                      [stretch]="true">
                  </svg-icon>
                  <span *ngIf="isFullSidebar"
                      class="ps-2">
                    {{ 'SIDEBAR.CONFIGURATION' | translate }}
                  </span>
                </a>
              </div>
            </div>
            <div class="custom-border-top d-md-none d-block"
                ngbTooltip="{{'SIDEBAR.LOGOUT' | translate}}"
                [disableTooltip]="isFullSidebar"
                (click)="logOut()">
              <a class="list-group-item list-group-item-action">
                <svg-icon src="assets/svg/icon-menu/logout.svg"
                    [stretch]="true"
                    [svgStyle]="{
                        height: 'inherit',
                        width: 'inherit',
                        'margin-left.px': -2,
                      }">
                </svg-icon>
                <span *ngIf="isFullSidebar"
                    class="ps-base-l7 text-truncate-clip">
                </span>
                <span *ngIf="isFullSidebar"
                    class="ps-base-l7 text-truncate-clip">
                  {{ 'SIDEBAR.LOGOUT' | translate }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Content -->
      <div #pageView
          class="container-page position-relative"
          [class.sidebar-shrink]="!isFullSidebar">
        <ng-container *appFeatureFlag="'ddoc'">
          <ng-container *ngIf="showWarning &&
              profile.otp_type === null &&
              (checkRouteActive('/pending-approval') ||
                checkRouteActive('/news-feed') ||
                checkRouteActive('/memos'))">
            <div class="box-warning d-flex w-100">
              <i class="fal fa-exclamation-triangle align-self-center me-2"
                  [style.color]="'rgb(216, 187, 24)'">
              </i>
              <span class="align-self-center">{{ 'CERTIFICATE.OTP-NOT-ENABLE' | translate }}</span>
              <button class="btn align-self-center ms-3"
                  [routerLink]="['/', 'profile-detail', 'otp']"
                  type="button">
                <span class="fs-base-l3">{{ 'CERTIFICATE.GOTO-OTP-SETTINGS' | translate }}</span>
              </button>
              <a class="text-black p-2 align-self-center ms-auto"
                  href="javascript:void(0)"
                  role="button"
                  (click)="showWarning = false">
                <i class="fal fa-times"></i>
              </a>
            </div>
          </ng-container>
        </ng-container>
        <div *ngIf="openSidebar"
            (click)="openSidebar = !openSidebar"
            class="background-blur"></div>
        <div #pageContainer
            class="container"
            appScrollTracker
            (scrollingFinished)="containerScrollingFinished()">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>