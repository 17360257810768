<ng-template #deleteConfirmationModal
    let-modal>
  <app-modal-confirmation-std4-skin [header]="header | translate"
      [title]="title | translate"
      [submitText]="'COMMON.Confirm' | translate"
      [dismissText]="'COMMON.Cancel' | translate"
      (dismiss)="modal.dismiss()"
      (submitted)="submit()">
    <ng-template>
      <svg height="56"
          width="56"
          fill="#1958d3">
        <use height="56"
            width="56"
            href="assets/images/icons/marks/question-fill.svg#question-fill"></use>
      </svg>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>

<div class="d-flex align-items-center"
    [ngClass]="{'justify-content-center' : !showDeleteMessage}"
    (click)="openModal(deleteConfirmationModal)">
  <svg class="pointer"
      stroke="currentColor"
      [attr.height]="svgSize.height"
      [attr.width]="svgSize.width">
    <use href="assets/images/icons/editing/trash.svg#trash"
        [attr.height]="svgSize.height"
        [attr.width]="svgSize.width">
    </use>
  </svg>
  <span *ngIf="showDeleteMessage"
      class="ms-2 pointer">
    {{ deleteMessage | translate }}
  </span>
</div>