import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss'],
})
export class DeleteConfirmationModalComponent {
  @Input() header = 'MEMOS.CONFIRM-DELETE';
  @Input() deleteMessage = 'MEMOS.DELETE';
  @Input() text = 'MEMOS.TEXT';
  @Input() title = 'MEMOS.Are you sure you want to delete this item';
  @Input() showDeleteMessage = true;
  @Input() data: any;
  @Input() svgSize = { height: 16, width: 16 };

  @Output() submitted = new EventEmitter();

  modal: NgbModalRef;

  private modalService = inject(NgbModal);

  openModal(content: TemplateRef<NgbModalRef>) {
    this.modal = this.modalService.open(content, {
      centered: true,
      size: 'size-smax56',
    });
  }

  submit() {
    this.submitted.emit({ data: this.data });
    this.modal.close();
  }
}
